/**
 * This mixin provides screen property
 * It's not meant to be used directly.
 *
 * @mixin
 */
import { SCREEN, SCREEN_BREAKPOINT_MEDIUM, SCREEN_BREAKPOINT_LARGE } from 'awds'

export const useScreen = () => {
  const screen = useState('c-screen', () => 'small')

  const isScreenLarge = computed(() => screen.value === SCREEN.large.name)
  const isScreenMedium = computed(() => screen.value === SCREEN.medium.name)
  const isScreenSmall = computed(() => screen.value === SCREEN.small.name)

  return {
    screen,
    isScreenLarge,
    isScreenMedium,
    isScreenSmall
  }
}

export const screenProp = {
  screen: {
    type: String,
    default: SCREEN.small.name,
    validator: (value: string) => [SCREEN.small.name, SCREEN.medium.name, SCREEN.large.name].includes(value)
  }
}

export const useSetScreen = () => {
  const { screen } = useScreen()
  const initListeners = () => {
    setScreenSize()
    window.addEventListener('resize', setScreenSize)
  }

  const setScreenSize = () => {
    const windowWidth = typeof window !== 'undefined' ? (document.documentElement?.clientWidth ?? window.innerWidth) : 0
    screen.value = getScreen(windowWidth)
  }

  const getScreen = (width: number) => {
    switch (true) {
      case width >= SCREEN_BREAKPOINT_LARGE:
        return 'large'
      case width >= SCREEN_BREAKPOINT_MEDIUM:
        return 'medium'
      case width < SCREEN_BREAKPOINT_MEDIUM:
      default:
        return 'small'
    }
  }

  onMounted(() => {
    initListeners()
  })

  onUnmounted(() => {
    window.removeEventListener('resize', setScreenSize)
  })
}
